@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
#root, html, body {
    margin:0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
}

.app{
    height: 100%;
    background:  url('./images/nature.jpg');
    background-color: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.5));
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.card{
    background-color: white;
    width: 60%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 25px;
    padding: 2%;
    box-shadow: 10px 10px;

}

.heading{
    display: flex;
    align-items: center;
    height: 580px;
    font-size: 2.5vw; 
    font-family: 'Source Code Pro', monospace;
}

.button {
  position: relative;
  outline: none;
  text-decoration: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  height: 200px;
  width: 210px;
  opacity: 1;
  background-color: #ffffff;
  border: 1px solid rgba(22, 76, 167, 0.6);
}
  
.button span {
  color: #164ca7;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.7px;
}
  
.button:hover {
  animation: rotate 0.7s ease-in-out both;
}
  
.button:hover span {
  animation: storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}
  
@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}
  
@keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0);
  }
  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0);
  }
  75% {
    transform: translate3d(2px, 0, 0) translateZ(0);
  }
  100% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
}  

@media only screen and (max-width: 600px) {
  .card {
      width: 80%;
      height: 20%;
  }
}